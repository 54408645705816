import { StaticImage } from 'gatsby-plugin-image';
import React from 'react';
import Layout from '../components/layout';

const WarehousingPage = () => {
  return (
    <Layout
      pageTitle="Warehousing"
      title="MRC Agrotech Ltd - Warehousing"
      description="MRC Agrotech has about 30000 MT Storage capacity with 5 own warehouses in Madhya Pradesh."
    >
      <div className="row justify-content-center">
        <div className="col-12 col-lg-8">
          <StaticImage
            data-sal="zoom-in"
            data-sal-easing="ease-out"
            className="mb-3"
            src="../images/warehouse.jpg"
            width={1000}
            height={342}
            alt="Warehouse"
          />
          <p data-sal="fade" data-sal-delay="100" data-sal-easing="ease-out">
            MRC Agrotech has about 30000 MT Storage capacity with 4 own warehouses in Madhya Pradesh This is in line
            with continuous up-gradation of facilities to be of better service to existing customers and also to
            discover additional business opportunities and expand operations. MRC plans to add more warehouses as demand
            peaks up.
          </p>
        </div>
        <div className="col-12 col-xl-8">
          <div className="row mb-4">
            <div className="col-lg-4">
              <h4>
                <StaticImage src="../images/icons/warehouse.png" alt="Warehouse" width={32} height={32} /> Our
                Warehouses
              </h4>
              <ul className="bullets1">
                <li>Savitri Warehouse</li>
                <li>Varsha Warehouse</li>
                <li>Anita Warehouse</li>
                <li>Riya Warehouse</li>
              </ul>
            </div>
            <div className="col-lg-4">
              <h4>
                <StaticImage src="../images/icons/location.png" alt="Location" width={32} height={32} /> Location
              </h4>
              <p className="warehouse-location-space">
                Village : Badodaram, Sothwa Road, Bhogika Square, Tehsil : Sheopur, District: Sheopur (MP)
              </p>
            </div>
            <div className="col-lg-4">
              <h4>
                <StaticImage src="../images/icons/capacity.png" alt="Capacity" width={32} height={32} /> Storage
                Capacity
              </h4>
              <ul className="bullets1">
                <li>30000 MT Storage capacity</li>
              </ul>
            </div>
            <div className="col-12">
                <a className="text-link-2 mb-3" href="/contact-us/#enquiry">For more details, please get in touch with us, click here.</a>
              </div>
          </div>
        </div>
        <div className="col-12 col-lg-10">
          <div className="row text-center">
            <div className="col-lg-4 my-2">
              <StaticImage width={300} height={302} src="../images/warehouse/circle-1.jpg" alt="Warehouse 1" />
            </div>
            <div className="col-lg-4 my-2">
              <StaticImage width={300} height={302} src="../images/warehouse/circle-2.jpg" alt="Warehouse 2" />
            </div>
            <div className="col-lg-4 my-2">
              <StaticImage width={300} height={302} src="../images/warehouse/circle-3.jpg" alt="Warehouse 3" />
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default WarehousingPage;
